import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiSolidChevronDown, BiMenu } from "react-icons/bi";
import { IoIosMenu } from "react-icons/io";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

import "../../css/style.css";
import "../../css/header.css";

import logo from "../../images/logo.jpg";
import headerImg from "../../images/header.jpg";
import empaIcon from "../../images/empa_icon.jpg";

const Header = (props) => {

    return (
        <div id="header">
            {/* desktop */}
            <div className="header-content container wrapper desktop-only">
                <div className="row mt-2">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-2 header-languages mt-4">
                                <span className="ms-2">
                                    <a className={props.language === "en" ? "fw-bold red" : ""} href="en/home">EN</a>
                                </span>
                                <span className="ms-3">
                                    <a className={props.language === "de" ? "fw-bold red" : ""} href="de/home">DE</a>
                                </span>
                            </div>

                            <div className="col-md-4 header-languages mt-4">
                                <span className="ms-3"><a href="https://www.empa.ch/web/empa/contact">{props.resources?.HeaderContact}</a></span>
                                <span className="ms-3"><a href="https://www.empa.ch/group/s604/akademie">Intranet</a></span>
                            </div>
                        </div>

                        {props.resources && (
                            <div className="row mt-4">
                                <div className="container wrapper mt-2">
                                    <div className="dropdown">
                                        <a className="dropdown-btn dropdown-a" href="https://www.empa.ch/web/akademie">Empa-Akademie</a>
                                    </div>

                                    <div className="dropdown">
                                        <button className="dropdown-btn active">
                                            <span>{props.resources?.HeaderEventCalendar} <BiSolidChevronDown /> </span>
                                        </button>
                                        <div className="dropdown-content">
                                            <a href={`/${props.language}/home`}>{props.resources?.HomeOverview}</a>
                                            <a href={`/${props.language}/home/veranstaltungen`}>{props.resources?.HeaderEvents}</a>
                                            <a href={`/${props.language}/home/fuehrungen`}>{props.resources?.HeaderGuidedTours}</a>
                                            <a href={`/${props.language}/home/openlabday`}>{props.resources?.HeaderDayOfOpenDoors}</a>
                                        </div>
                                    </div>

                                    <div className="dropdown">
                                        <button className="dropdown-btn">
                                            <span>{props.resources?.HeaderInFocus} <BiSolidChevronDown /> </span>
                                        </button>
                                        <div className="dropdown-content">
                                            <a href="https://www.empa.ch/web/akademie/digital-science">{props.resources?.HeaderInFocusDigitalization}</a>
                                            <a href="https://www.empa.ch/web/akademie/environment-and-health">{props.resources?.HeaderInFocusEnvironment}</a>
                                            <a href="https://www.empa.ch/web/akademie/smart-materials">{props.resources?.HeaderInFocusSmartMaterials}</a>
                                        </div>
                                    </div>

                                    <div className="dropdown">
                                        <a className="dropdown-btn dropdown-a" href="https://www.empa.ch/web/akademie/venue">{props.resources?.HeaderVenue}</a>
                                    </div>

                                    <div className="dropdown">
                                        <a className="dropdown-btn dropdown-a" href="https://www.empa.ch/web/akademie/about-us">{props.resources?.HeaderAboutUs}</a>
                                    </div>

                                    <div className="dropdown">
                                        <a className="dropdown-btn dropdown-a" href="https://www.empa.ch/"><img src={empaIcon} alt="icon" /> EMPA</a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="col-md-3 text-end">
                        <a alt="EMPA - Akademie" href="https://www.empa.ch/web/akademie/"><img id="logo" alt="logo" src={logo} /></a>
                    </div>
                </div>
            </div>

            {/* mobile - tablet */}
            <div className="mobile-tablet-only">
                <Navbar key={false} expand={false} className="mobile-header-bg-color">
                    <Container fluid>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`}> <IoIosMenu className="nav-menu" /> </Navbar.Toggle>
                        <div className="mobile-navbar-links">
                            <span className="ms-2">
                                <a className={props.language === "en" ? "fw-bold red" : ""} href="en/home">EN</a>
                            </span>
                            <span className="ms-3">
                                <a className={props.language === "de" ? "fw-bold red" : ""} href="de/home">DE</a>
                            </span>
                            <span className="ms-3"><a href="https://www.empa.ch/web/empa/contact">{props.resources?.HeaderContact}</a></span>
                            <span className="ms-3"><a href="https://www.empa.ch/group/s604/akademie">Intranet</a></span>
                        </div>
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${false}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
                            placement="start"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${false}`}>

                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3 fw-bold">
                                    <Nav.Link className="ms-4" href="https://www.empa.ch/web/empa">EMPA</Nav.Link>
                                    <Nav.Link className="ms-4" href="https://www.empa.ch/web/akademie">912 - Akademie</Nav.Link>
                                    <hr />
                                    <Nav.Link className="ms-4" href="https://www.empa.ch/web/akademie/overview">Empa-Akademie</Nav.Link>
                                    <NavDropdown
                                        className="ms-4"
                                        title={props.resources?.HeaderEventCalendar}
                                        id={`offcanvasNavbarDropdown-expand-${false}`}
                                    >
                                        <NavDropdown.Item className="nav-link mt-1 ms-3" href={`/${props.language}/home`}>{props.resources?.HomeOverview}</NavDropdown.Item>
                                        <NavDropdown.Item className="nav-link mt-1 ms-3" href={`/${props.language}/home/veranstaltungen`}>{props.resources?.HeaderEvents}</NavDropdown.Item>
                                        <NavDropdown.Item className="nav-link mt-1 ms-3" href={`/${props.language}/home/fuehrungen`}>{props.resources?.HeaderGuidedTours}</NavDropdown.Item>
                                        <NavDropdown.Item className="nav-link mt-1 ms-3" href={`/${props.language}/home/openlabday`}>{props.resources?.HeaderDayOfOpenDoors}</NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown
                                        className="ms-4"
                                        title={props.resources?.HeaderInFocus}
                                        id={`offcanvasNavbarDropdown-expand-${false}`}
                                    >
                                        <NavDropdown.Item className="nav-link mt-1 ms-3" href="https://www.empa.ch/web/akademie/digital-science">{props.resources?.HeaderInFocusDigitalization}</NavDropdown.Item>
                                        <NavDropdown.Item className="nav-link mt-1 ms-3" href="https://www.empa.ch/web/akademie/environment-and-health">{props.resources?.HeaderInFocusEnvironment}</NavDropdown.Item>
                                        <NavDropdown.Item className="nav-link mt-1 ms-3" href="https://www.empa.ch/web/akademie/smart-materials">{props.resources?.HeaderInFocusSmartMaterials}</NavDropdown.Item>
                                    </NavDropdown>
                                    <Nav.Link className="ms-4" href="https://www.empa.ch/web/akademie/venue">{props.resources?.HeaderVenue}</Nav.Link>
                                    <Nav.Link className="ms-4" href="https://www.empa.ch/web/akademie/about-us">{props.resources?.HeaderAboutUs}</Nav.Link>
                                    <Nav.Link className="ms-4" href="https://www.empa.ch/"><img src={empaIcon} alt="icon" /> EMPA</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>


            </div>







            <div className="header-image-container hidden-mobile">
                <div className="header-content container wrapper">
                    <div className="row">
                        <div className="col-md-12 text-end">
                            <img className="header-image" alt="header" src={headerImg} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
